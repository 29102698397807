import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css'

import { BrowserRouter, Route, Switch, Redirect, BrowserRouter as Router  } from 'react-router-dom'




const Root = () => {

return(
  <div className="App">
    <BrowserRouter>
  <Switch>
    {/* <Route exact path='/' component={Pageaccueil}></Route> */}
    <Route exact path='/' component={App}></Route>
    
  </Switch>
  </BrowserRouter>
  </div>
)

}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
