import React from 'react'
import react, { useState, useRef} from 'react';
import './App.css';

const Boutonexcluinput_zone_long = ({
    variable, titre,
    reclicbouton,
    textb1, textb2, textb3, textb4, textb5, textb6, textb7, textb8, textb9, textb10,
    setParentvariable,
    actb1, actb2, actb3, actb4, actb5, actb6, actb7, actb8, actb9, actb10,
    nombredebouton,
    modeactivation, activevariable, activationvaleur, modeactivationsupegale,
    placeholderinputtext

}) => {


const [Variable, setVariable] = useState(variable)

function handleChangeInput(e) {
    setVariable(e.target.value);
    setParentvariable(e.target.value)
  }


let activetitre = false

if (titre!==""){
    activetitre = true
}



const onClickb1 =function() {


    if (Variable !== actb1) {
        setParentvariable(actb1)
        setVariable(actb1)
    }

    if (Variable === actb1) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }

}

const onClickb2 =function() {
    if (Variable !== actb2) {
        setParentvariable(actb2)
        setVariable(actb2)
    }

    if (Variable === actb2) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb3 =function() {
    if (Variable !== actb3) {
        setParentvariable(actb3)
        setVariable(actb3)
    }

    if (Variable === actb3) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb4 =function() {
    if (Variable !== actb4) {
        setParentvariable(actb4)
        setVariable(actb4)
    }

    if (Variable === actb4) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb5 =function() {
    if (Variable !== actb5) {
        setParentvariable(actb5)
        setVariable(actb5)
    }

    if (Variable === actb5) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb6 =function() {
    if (Variable !== actb6) {
        setParentvariable(actb6)
        setVariable(actb6)
    }

    if (Variable === actb6) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb7 =function() {
    if (Variable !== actb7) {
        setParentvariable(actb7)
        setVariable(actb7)
    }

    if (Variable === actb7) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb8 =function() {
    if (Variable !== actb8) {
        setParentvariable(actb8)
        setVariable(actb8)
    }

    if (Variable === actb8) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb9 =function() {
    if (Variable !== actb9) {
        setParentvariable(actb9)
        setVariable(actb9)
    }

    if (Variable === actb9) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

const onClickb10 =function() {
    if (Variable !== actb10) {
        setParentvariable(actb10)
        setVariable(actb10)
    }

    if (Variable === actb10) {
        setParentvariable(reclicbouton)
        setVariable(reclicbouton)
    }
}

if (modeactivation===false) {

    if (nombredebouton===0) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
    }

    if (nombredebouton===1) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                <textarea className='champ_long' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
    }

    if (nombredebouton===2) {
        return (
            <div>
            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
    } 

    if (nombredebouton===3) {
        return (
            <div>
            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button> 
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
    } 

    if (nombredebouton===4) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>    
            </div>
        )
    } 

    if (nombredebouton===5) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>       
            </div>
        )
    }  

    if (nombredebouton===6) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>  
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>        
            </div>
        )
    }

    if (nombredebouton===7) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button> 
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>           
            </div>
        )
    }

    if (nombredebouton===8) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
            <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
    }

    if (nombredebouton===9) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
            <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
            <button className={Variable==actb9 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb9()}>{textb9}</button> 
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
    }

    if (nombredebouton===10) {
        return (
            <div>
                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
            <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
            <button className={Variable==actb9 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb9()}>{textb9}</button> 
            <button className={Variable==actb10 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb10()}>{textb10}</button>
            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/> 
            </div>
        )
    }

}

if (modeactivation===true) {

        if (modeactivationsupegale===1) {

                if(activevariable>activationvaleur){

                    if (nombredebouton===0) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                                <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    }


                    if (nombredebouton===1) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                                <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                                <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    }
                
                    if (nombredebouton===2) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    } 
                
                    if (nombredebouton===3) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    } 
                
                    if (nombredebouton===4) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>    
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    } 
                
                    if (nombredebouton===5) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>      
                            </div>
                        )
                    }  
                
                    if (nombredebouton===6) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>         
                            </div>
                        )
                    }
                
                    if (nombredebouton===7) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>           
                            </div>
                        )
                    }
                
                    if (nombredebouton===8) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
                            <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    }
                
                    if (nombredebouton===9) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
                            <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
                            <button className={Variable==actb9 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb9()}>{textb9}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    }
                
                    if (nombredebouton===10) {
                        return (
                            <div>
                                            {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                            <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                            <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                            <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                            <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                            <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
                            <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
                            <button className={Variable==actb9 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb9()}>{textb9}</button> 
                            <button className={Variable==actb10 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb10()}>{textb10}</button> 
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                            </div>
                        )
                    }
                }        


    

                if (activevariable<=activationvaleur) {
                    return(
                        null
                    )
                    
                }
        } 
        
        
        if (modeactivationsupegale===2) {

            if(activevariable===activationvaleur){


                if (nombredebouton===0) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                }

                if (nombredebouton===1) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                            <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                            <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                }
            
                if (nombredebouton===2) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                } 
            
                if (nombredebouton===3) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button> 
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                } 
            
                if (nombredebouton===4) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>  
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>  
                        </div>
                    )
                } 
            
                if (nombredebouton===5) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                        <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button> 
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>      
                        </div>
                    )
                }  
            
                if (nombredebouton===6) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                        <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                        <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>  
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>        
                        </div>
                    )
                }
            
                if (nombredebouton===7) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                        <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                        <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                        <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>  
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>          
                        </div>
                    )
                }
            
                if (nombredebouton===8) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                        <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                        <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                        <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
                        <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                }
            
                if (nombredebouton===9) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                        <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                        <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                        <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
                        <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
                        <button className={Variable==actb9 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb9()}>{textb9}</button> 
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                }
            
                if (nombredebouton===10) {
                    return (
                        <div>
                                        {activetitre ? <p className="titrebouton">{titre}</p> : null}
                        <button className={Variable==actb1 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb1()}>{textb1} </button>
                        <button className={Variable==actb2 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb2()}>{textb2} </button>
                        <button className={Variable==actb3 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb3()}>{textb3}</button>
                        <button className={Variable==actb4 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb4()}>{textb4}</button>
                        <button className={Variable==actb5 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb5()}>{textb5}</button>
                        <button className={Variable==actb6 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb6()}>{textb6}</button>
                        <button className={Variable==actb7 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb7()}>{textb7}</button>            
                        <button className={Variable==actb8 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb8()}>{textb8}</button> 
                        <button className={Variable==actb9 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb9()}>{textb9}</button> 
                        <button className={Variable==actb10 ? "btn btn-success" : "btn btn-info"} onClick={() => onClickb10()}>{textb10}</button> 
                        <textarea className='champ' value={Variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
                        </div>
                    )
                }
            }        




            if(activevariable!==activationvaleur) {
                return(
                    null
                )
                
            }
        }  

    
}




}

export default Boutonexcluinput_zone_long
