import React from 'react'
import react, { useState, useRef} from 'react';
import './App.css';

const Inputbio = ({
    texte, setParentvariableinput, placeholderinputtext,
    modeactivation, modeactivationsupegale, activevariable, activationvaleur, type, taille, grostitre

}) => {

    let [Nombre , setNumbre] = useState();

    function handleChangeInput(e) {
        setNumbre(e.target.value);
        setParentvariableinput(e.target.value)
      }

    if (modeactivation===false) {
        return (
            <div>
            <p className="titrebouton">{grostitre}</p>
            <div className="inputbioligne">
            <p className="texteinputbio">{texte}</p>
            <input type={type} className={taille} value={Nombre} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
            </div>
        )
    }

    if (modeactivation===true) {

        
        if (modeactivationsupegale===1) {

            if(activevariable>activationvaleur){
                return (

                    <div>
                    <p className="titrebouton">{grostitre}</p>
                    <div className="inputbioligne">
                        
                    <p className="texteinputbio">{texte}</p>
            
                    <input type={type} className={taille} value={Nombre} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            
                    </div>
                    </div>
                )
            }

            else {
                return(
                    null
                )
            }

        }


            
                    
        if (modeactivationsupegale===2) {

            if(activevariable===activationvaleur){
                return (
                    <div>
                        <p className="titrebouton">{grostitre}</p>

                    <div className="inputbioligne">
                        
                    <p className="texteinputbio">{texte}</p>
            
                    <input type={type} className={taille} value={Nombre} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            
                    </div>
                    </div>
                )
            }

            else {
                return(
                    null
                )
            }

        }

    }
}

export default Inputbio
