import React from 'react'
import react, { useState, useRef} from 'react';
import './App.css';

const Aff_chimio = ({
    variable, titre,
    setParentvariable,
    placeholderinputtext

}) => {


function handleChangeInput(e) {
    setParentvariable(e.target.value)
  }
        return (
            <div>
                {<p className="titrebouton">{titre}</p>}
                <input type="texte" className='champ' value={variable} onChange={handleChangeInput}  placeholder={placeholderinputtext}/>
            </div>
        )
        
}


export default Aff_chimio
