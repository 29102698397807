// import logo from './logo.svg';
// import './App.css';
import './index.css';

import React from 'react'
import { useHistory } from 'react-router-dom'
import react, { useState, useRef, useEffect} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import iconlogo from './logo.jpg';
import { Container, Accordion, Card, Button, Navbar, Nav, NavDropdown, Form, FormControl, Dropdown } from "react-bootstrap";
import Bontonexclu from './Boutonexclu';
import Boutonpluri from './Boutonpluri';
import Boutonexcluinput_zone from './Boutonexcluinput_zone';
import Inputbio from './Inputbio';
import Inputbiotitre from './Inputbiotitre';
import Inputtextcourt from './Inputtextcourt';
import Zonetexte from './Zonetexte';
import Boutonexcluinput from './Boutonexcluinput';
import Boutonexcluinput_zone_long from './Boutonexcluinput_zone_long';
import Afficheurdroite from './Afficheurdroite';
import content_copy_black_24dp from './content_copy_black_24dp.svg'
import NewLineToBr from './NewLineToBr';
import Aff_chimio from './Aff_chimio'
import Image_copier from './copier.png';



function App() {


    useEffect(() => {
        if (window.sa_event) {
          window.sa_event('ouverture_page_LDL');
        }
      }, []);

    // INTRO
    const [Chimio, setChimio] = useState("");
    const [Primitif, setPrimitif] = useState("");
    const [Place, setPlace] = useState("");
    const [Lateralite, setLateralite] = useState("");
    const [Stade, setStade] = useState("");
    const [Rh, setRh] = useState("");
    const [Her, setHer] = useState("");
    const [Cycle, setCycle] = useState("");
    const [Cycleoucure, setCycleoucure] = useState("cycle");
    const [Cycleprevu, setCycleprevu] = useState("");
    const [Lignemeta, setLignemeta] = useState("");

    const [Exampretherapeutique, setExampretherapeutique] = useState("");


    const [AjoutHER2, setAjoutHER2] = useState("");
    const [NOMHER2, setNOMHER2] = useState("");
    const [CycleHER2, setCycleHER2] = useState("");
    const [InputChimio, setInputChimio] = useState("");
    const [InputIndication, setInputIndication] = useState("");
    const [Evtintercu, setEvtintercu] = useState("");
    const [Evtinterinput, setEvtinterinput] = useState("");
    const [Fievre, setFievre] = useState("");
    const [Fievreinput, setFievreinput] = useState("");

    const [Ps, setPs] = useState("");
    const [Inputcejour, setInputcejour] = useState("");
 

    const [Biovalidation, setBiovalidation] = useState("");
    const [Hb, setHb] = useState("");
    const [Pnn, setPnn] = useState("");
    const [Pl, setPl] = useState("");
    const [Cr, setCr] = useState("");
    const [Na, setNa] = useState("");
    const [K, setK] = useState("");
    const [Cac, setCac] = useState("");
    const [Alat, setAlat] = useState("");
    const [Asat, setAsat] = useState("");
    const [Bilit, setBilit] = useState("");
    const [Bilic, setBilic] = useState("");
    const [TSH, setTSH] = useState("");
    const [T4, setT4] = useState("");
    const [Cortisol, setCortisol] = useState("");
    const [BNP, setBNP] = useState("");
    const [Tropo, setTropo] = useState("");

    const [Inputobservbio, setInputobservbio] = useState("");

    const [Poids, setPoids] = useState("");
    const [Poidsvariation, setPoidsvariation] = useState("");
    const [InputCliniqueGeneral, setInputCliniqueGeneral] = useState("");

    const [Alimentationtype, setAlimentationtype] = useState("Alimentation orale conservée");
    const [Alimentationappetit, setAlimentationappetit] = useState("");
    const [Alimentationcno, setAlimentationcno] = useState("");
    const [Alimentationqqtentreale, setAlimentationqqtentreale] = useState("");
    const [Alimentationenteraltolerance, setAlimentationenteraltolerance] = useState("");

    const [Toleranceglobale, setToleranceglobale] = useState("");

    const [Asthenie, setAsthenie] = useState("");
    const [Dureasthenie, setDureasthenie] = useState("");
    const [Inputasthenie, setInputasthenie] = useState("");

    const [Nausee, setNausee] = useState("");
    const [Nauseeduree, setNauseeduree] = useState("");

    const [Vomissement, setVomissement] = useState("");
    const [Vomissementduree, setVomissementduree] = useState("");

    const [Nauseeinput, setNauseeinput] = useState("");



    const [Diarrhee, setDiarrhee] = useState("");
    const [Diarrheeduree, setDiarrheeduree] = useState("");
    const [Diarrheeinput, setDiarrheeinput] = useState("");

    const [Mucite, setMucite] = useState("");
    const [Muciteinput, setMuciteinput] = useState("");

    // const [Sdmainpieds, setSdmainpieds] = useState("");
    // const [Sdmainpiedsinput, setSdmainpiedsinput] = useState("");

    const [Neuropathie, setNeuropathie] = useState("");
    const [Dureeneuropathie, setDureeneuropathie] = useState("");
    const [Neuropathieinput, setNeuropathieinput] = useState("");

    const [Toxunguéale, setToxunguéale] = useState("");
    const [Inputtoxunguéale, setInputtoxunguéale] = useState("");
    const [Alocepie, setAlocepie] = useState("");
    const [Alopecieinput, setAlopecieinput] = useState("");

    const [HTA, setHTA] = useState("");
    const [HTAinput, setHTAinput] = useState("");
    const [Saignement, setSaignement] = useState("");
    const [Saignementinput, setSaignementinput] = useState("");

    
    const [Protéinurie, setProtéinurie] = useState("");
    const [Protéinurieinout, setProtéinurieinout] = useState("");


    const [Eruptioncutannee, setEruptioncutannee] = useState("");
    const [Eruptioncutanneeinput, setEruptioncutanneeinput] = useState("");


    const [Acnee, setAcnee] = useState("");
    const [Acneeinput, setAcneeinput] = useState("");

    const [Dyspnee, setDyspnee] = useState("");
    const [Dyspneeinput, setDyspneeinput] = useState("");

    const [InputCliniqueSpe, setInputCliniqueSpe] = useState("");

    const [GCSF, setGCSF] = useState("");
    // const [GCSFtolerance, setGCSFtolerance] = useState("");
    const [GCSFinput, setGCSFinput] = useState("");

    const [Transfu, setTransfu] = useState("");
    
    const [Ta, setTa] = useState("");    
    const [FC, setFc] = useState("");    
    const [Sat, setSat] = useState("");    
    const [Temp, setTemp] = useState("");  
    const [Cstinput, setCstinput] = useState("");     
    
    const [Tumeurenplace, setTumeurenplace] = useState("");      
    const [Cardioauscult, setCardioauscult] = useState("");  
    const [Cardisp, setCardiosp] = useState(""); 
    const [Cardioinput, setCardioinput] = useState("");   
    const [Respiauscult, setRespiauscult] = useState("");  
    const [Respiinput, setRespiinput] = useState("");   
    const [Abdopal, setAbdopal] = useState("");  
    const [Abdoinput, setAbdoinput] = useState("");     
    const [Examautreinout, setExamautreinout] = useState("");  
    
    const [Validation, setValidation] = useState(""); 
    const [Validationinput, setValidationinput] = useState("");
    const [Datecure, setDatecure] = useState("");  
    const [Causenonvalidation, setCausenonvalidation] = useState("");  


    const [Produit1, setProduit1] = useState(""); 
    const [Doseprevu1, setDoseprevu1] = useState(""); 
    const [Reduction1, setReduction1] = useState(""); 
    const [Reductioncause1, setReductioncause1] = useState(""); 
    const [Dosetotale1, setDosetotale1] = useState("");  
    const [Dosecomm1, setDosecomm1] = useState("");  


    const [Produit2, setProduit2] = useState(""); 
    const [Doseprevu2, setDoseprevu2] = useState(""); 
    const [Reduction2, setReduction2] = useState(""); 
    const [Reductioncause2, setReductioncause2] = useState(""); 
    const [Dosetotale2, setDosetotale2] = useState("");  
    const [Dosecomm2, setDosecomm2] = useState("");  


    const [Produit3, setProduit3] = useState(""); 
    const [Doseprevu3, setDoseprevu3] = useState(""); 
    const [Reduction3, setReduction3] = useState(""); 
    const [Reductioncause3, setReductioncause3] = useState(""); 
    const [Dosetotale3, setDosetotale3] = useState("");  
    const [Dosecomm3, setDosecomm3] = useState("");  


    const [Produit4, setProduit4] = useState(""); 
    const [Doseprevu4, setDoseprevu4] = useState(""); 
    const [Reduction4, setReduction4] = useState(""); 
    const [Reductioncause4, setReductioncause4] = useState(""); 
    const [Dosetotale4, setDosetotale4] = useState("");  
    const [Dosecomm4, setDosecomm4] = useState("");  


    const [Doseinput, setDoseinput] = useState("");
    
    const [Prochainecuredate, setProchainecuredate] = useState("");
    const [Examreevaluation, setExamreevaluation] = useState("");
    const [Prochainecsreferent, setProchainecsreferent] = useState("");
    const [Suiteinput, setSuiteinput] = useState("");

    const [isVisibledose, setIsVisibledose] = useState(false); // Défini initialement à false pour cacher l'élément

    useEffect(() => {
        if (Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="") {
            setIsVisibledose(true); // Rend le composant visible si 'dose' n'est pas une chaîne vide

        } else {
            setIsVisibledose(false); // Rend le composant caché si 'dose' est une chaîne vide
        }
    }, [Doseprevu1, Doseprevu2, Doseprevu3, Doseprevu4, Dosetotale1, Dosetotale2, Dosetotale3, Dosetotale4, Dosecomm1, Dosecomm2, Dosecomm3, Dosecomm4]); // La dépendance 'dose' assure que useEffect s'exécute à chaque changement de 'dose'


    function handleChangeInputCyclecejour(e) {
        setCycle(e.target.value);
      }

      function handleChangeInputCycleprevu(e) {
        setCycleprevu(e.target.value);
      }      


      const onClickCycle =function() {


        if (Cycleoucure !== "cycle") {
            setCycleoucure("cycle")
        }
    
        if (Cycleoucure === "cycle") {
            setCycleoucure("")
        }
    
    }
    
    const onClickCure =function() {
        if (Cycleoucure !== "cure") {
            setCycleoucure("cure")
        }
    
        if (Cycleoucure === "cure") {
            setCycleoucure("")
        }
    }

    useEffect(() => {
        // Fonction pour obtenir un paramètre de l'URL
        const getQueryParam = (param) => {
          const searchParams = new URLSearchParams(window.location.search);
          return searchParams.get(param);
        };
    
        // Fonction pour supprimer la query string de l'URL
        const removeQueryString = () => {
          const url = new URL(window.location.href);
          url.search = '';
          window.history.replaceState({}, '', url);
        };
    
        // Récupérer la valeur et la définir
        const value_chimio = getQueryParam('Chimio') || '';
        const value_cycle = getQueryParam('Cycle') || '';
        const value_produit1 = getQueryParam('Produit1') || '';
        const value_produit2 = getQueryParam('Produit2') || '';
        const value_produit3 = getQueryParam('Produit3') || '';
        const value_produit4 = getQueryParam('Produit4') || '';
        setChimio(value_chimio);
        setCycle(value_cycle)
        setProduit1(value_produit1)
        setProduit2(value_produit2)
        setProduit3(value_produit3)
        setProduit4(value_produit4)
    
        // Supprimer la query string de l'URL
        removeQueryString();
      }, []);

      const jsxContentRef = useRef(); // Référence pour accéder au contenu JSX

    //   const copyToClipboard = () => {
    //     let content = jsxContentRef.current.innerHTML;
    //     // Remplacez les balises <br/> par un saut de ligne HTML
    //     content = content.replace(/<br\s*\/?>/gi, '&#10;');
      
    //     // Créer un élément temporaire pour convertir les entités HTML en texte
    //     const tempDiv = document.createElement('div');
    //     tempDiv.innerHTML = content;
    //     const textToCopy = tempDiv.textContent || tempDiv.innerText || "";
      
    //     navigator.clipboard.writeText(textToCopy) // Copiez dans le presse-papiers
    //       .then(() => alert('Copié dans le presse-papiers!'))
    //       .catch(err => console.error('Erreur lors de la copie :', err));
    //   };

      const copyToClipboard = () => {
        const content = jsxContentRef.current.innerHTML;
        const blob = new Blob([content], { type: 'text/html' });
        const data = [new ClipboardItem({ 'text/html': blob })];
      
        navigator.clipboard.write(data)
          .then(() => alert('Copié dans le presse-papiers avec formatage!'))
          .catch(err => console.error('Erreur lors de la copie :', err));
      };
      

    return (
<div className="App">

<div className="Header">

{/* <span className='texte_header_bold'>Clinicos.fr</span> */}
<span className='texte_header'> Cet outil est un générateur de CR de chimiothérapie. Les CR sont à <span className='texte_header_bold'>copier-coller dans votre logiciel hospitalier</span>. Ils sont produits en local sur votre ordinateur et <span className='texte_header_bold'>ne sont pas enregistrés.</span></span>




    </div>  

    <div className="droitegauche">

        <div className="gauche">

        <div className="gaucherectangle">

                <div className="accordion"> 
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="titreacc">Cure & traitement</p>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>


                    <div className="gauchesection">

                    <div className="gauche33">
                          <Aff_chimio
                              titre={"CURE : "}
                              variable={Cycle}
                              setParentvariable={setCycle}
                              placeholderinputtext={"exemple : C1j8"}
                              
                              />
                    </div>

                        <div className="gauche66">
                        <Aff_chimio
                            titre={"CHIMIOTHERAPIE :"}
                            variable={Chimio}
                            setParentvariable={setChimio}
                            placeholderinputtext={"entrez ici le nom de la chimiotherapie"}
                            />
                        </div>




                    <div className="gauchesection">
                    



                    </div>

                    </div>


                    {/* <p className="titreitem">Rappel contexte de prise en charge (facultatif)</p>

                    <div className="gauchesection">
                    <div className="gauche100">
                        <Zonetexte
                        setParentvariableinput={setInputIndication}
                        placeholderinputtext={"Indication  -  taille modifiable en déplacant le coin inférieur gauche"}
                        titre={""}
                        />
                    </div>
                    </div>   */}

                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            </div>


                            {/* <div className="accordion"> 
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            <p className="titreacc">EXAMEN(s) PRE-THERAPEUTIQUE</p>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>

                        <div className="gauchesection">
                            <div className="gauche100">
                                <Zonetexte
                                setParentvariableinput={setExampretherapeutique}
                                placeholderinputtext={"ex : scintigraphie cardiaque"}
                                titre={"Examen(s) pré-thérapeutique :"}
                                />
                            </div>
                            </div>   

                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                </div> */}



            <div className="accordion"> 
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="titreacc">Information pré-théreupique et clinique</p>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>

                    <div className="gauchesection">
                            <div className="gauche100">
                                <Zonetexte
                                setParentvariableinput={setExampretherapeutique}
                                placeholderinputtext={"ex : scintigraphie cardiaque"}
                                titre={"Examen(s) pré-thérapeutique :"}
                                champ={"champ"}
                                />
                            </div>
                    </div>   


 


                            <div className="gauche100">
                                    <p className="titreitem">EVENEMENT(s) INTERCURRENT(s) :</p>
                    </div>     


                    <div className="gauchesection">

                        <div className="gauche100">
                        <Bontonexclu
                        titre={"Evenements intercurrents, élements médicaux nouveaux (ex : CS avec le référent) :"}
                        modeactivation={false}
                        nombredebouton={1}
                        variable={Evtintercu}
                        setParentvariable={setEvtintercu}
                        reclicbouton={""}
                        textb1={"Pas d'évènement intercurrent, ni d'épisode de fièvre"}
                        actb1={"Pas d'évènement intercurrent, ni d'épisode de fièvre."}
                        placeholderinputtext={"Zone de texte libre"}
                        />
                        </div>
                      </div>

                    <div className='gauchesection'>
                        <div className='gauche100'>
                        <Zonetexte
                                setParentvariableinput={setEvtinterinput}
                                placeholderinputtext={"Evenement intercurrent - texte libre"}
                                titre={""}
                                champ={"champ_long"}
                                />
                        </div>
                    </div>  


                    <div className="gauche100">
                                    <p className="titreitem">CE JOUR :</p>
                    </div>  

                                <div className="gauchesection">
                                
                                <div className="gauche50">
                                <Bontonexclu
                                    titre={"PS :"}
                                    modeactivation={false}
                                    nombredebouton={5}
                                    variable={Ps}
                                    setParentvariable={setPs}
                                    reclicbouton={""}
                                    textb1={"PS 0"}
                                    textb2={"PS 1"}
                                    textb3={"PS 2"}
                                    textb4={"PS 3"}
                                    textb5={"PS 4"}
                                    actb1={"0"}
                                    actb2={"1"}
                                    actb3={"2"}
                                    actb4={"3"}
                                    actb5={"4"}
                                    />
                                </div>
                                


                                

                                <div className="gauche50">
                                <Boutonexcluinput
                                    titre={"Poids :"}
                                    modeactivation={false}
                                    nombredebouton={0}
                                    variable={Poids}
                                    setParentvariable={setPoids}
                                    reclicbouton={""}
                                    placeholderinputtext={"ex : 55"}
                                    />

                                    <Boutonexcluinput
                                    titre={"Variation du poids :"}
                                    modeactivation={false}
                                    nombredebouton={1}
                                    variable={Poidsvariation}
                                    setParentvariable={setPoidsvariation}
                                    reclicbouton={""}
                                    placeholderinputtext={"ex : -2kg en 7 jours"}
                                    textb1={"Stable"}
                                    actb1={"stable"}
                                    />
                                </div>

                                </div>


                                {/* <div className="gauchesection">

                                    <div className="gauche33">
                                    <Boutonexcluinput
                                    titre={"Appétit : "}
                                    modeactivation={true}
                                    modeactivationsupegale={2}
                                    activevariable={Alimentationtype}
                                    activationvaleur={"Alimentation orale conservée"}
                                    nombredebouton={3}
                                    variable={Alimentationappetit  }
                                    setParentvariable={setAlimentationappetit  }
                                    reclicbouton={""}
                                    placeholderinputtext={""}
                                    textb1={"Appétit conservé"}
                                    textb2={"Anorexie"}
                                    textb3={"Anorexie sévère"}
                                    actb1={"l'appétit est conservé"}
                                    actb2={"est rapporté une anorexie "}
                                    actb3={"est rapporté une anorexie sévère"}
                                    />
                                                                        <Boutonexcluinput
                                    titre={"Appétit : "}
                                    modeactivation={true}
                                    modeactivationsupegale={2}
                                    activevariable={Alimentationtype}
                                    activationvaleur={"Alimentation orale mixée conservée"}
                                    nombredebouton={3}
                                    variable={Alimentationappetit  }
                                    setParentvariable={setAlimentationappetit  }
                                    reclicbouton={""}
                                    placeholderinputtext={""}
                                    textb1={"Appétit conservé"}
                                    textb2={"Anorexie"}
                                    textb3={"Anorexie sévère"}
                                    actb1={"l'appétit est conservé"}
                                    actb2={"est rapporté une anorexie"}
                                    actb3={"est rapporté une anorexie sévère"}
                                    />

                                    <Boutonexcluinput
                                    titre={"Quantité par jour :"}
                                    modeactivation={true}
                                    modeactivationsupegale={2}
                                    activevariable={Alimentationtype}
                                    activationvaleur={"Alimentation entérale"}
                                    nombredebouton={3}
                                    variable={Alimentationqqtentreale   }
                                    setParentvariable={setAlimentationqqtentreale   }
                                    reclicbouton={""}
                                    placeholderinputtext={""}
                                    textb1={"500ml/j"}
                                    textb2={"1 l/j"}
                                    textb3={"1,5 l/j"}
                                    actb1={"500ml/j"}
                                    actb2={"1 l/j"}
                                    actb3={"1,5 l/j"}
                                    />

                                    </div>

                                    <div className="gauche33">
                                    <Boutonexcluinput
                                    titre={"Nombre de CNO/j : "}
                                    modeactivation={true}
                                    modeactivationsupegale={2}
                                    activevariable={Alimentationtype}
                                    activationvaleur={"Alimentation orale conservée"}
                                    nombredebouton={3}
                                    variable={Alimentationcno   }
                                    setParentvariable={setAlimentationcno   }
                                    reclicbouton={""}
                                    placeholderinputtext={"ex : 3"}
                                    textb1={"Pas de prise de CNO"}
                                    textb2={"1"}
                                    textb3={"2"}
                                    actb1={"pas de prise de CNO"}
                                    actb2={"1"}
                                    actb3={"2"}
                                    />
                                                                        <Boutonexcluinput
                                    titre={"Nombre de CNO/j : "}
                                    modeactivation={true}
                                    modeactivationsupegale={2}
                                    activevariable={Alimentationtype}
                                    activationvaleur={"Alimentation orale mixée conservée"}
                                    nombredebouton={3}
                                    variable={Alimentationcno   }
                                    setParentvariable={setAlimentationcno   }
                                    reclicbouton={""}
                                    placeholderinputtext={"ex : 3"}
                                    textb1={"Pas de prise de CNO"}
                                    textb2={"1"}
                                    textb3={"2"}
                                    actb1={"pas de prise de CNO"}
                                    actb2={"1"}
                                    actb3={"2"}
                                    />

                                    <Boutonexcluinput
                                    titre={"Tolérance alimentation :"}
                                    modeactivation={true}
                                    modeactivationsupegale={2}
                                    activevariable={Alimentationtype}
                                    activationvaleur={"Alimentation entérale"}
                                    nombredebouton={3}
                                    variable={Alimentationenteraltolerance    }
                                    setParentvariable={setAlimentationenteraltolerance    }
                                    reclicbouton={""}
                                    placeholderinputtext={""}
                                    textb1={"bonne tolérance"}
                                    textb2={"moyenne tolérance"}
                                    textb3={"mauvaise tolérance"}
                                    actb1={"bonne tolérance"}
                                    actb2={"moyenne tolérance"}
                                    actb3={"mauvaise tolérance"}
                                    />
                                    </div>

                                    



                                </div> */}


                                <div className="gauchesection">

                                <div className="gauche100">
                            <Zonetexte
                                setParentvariableinput={setInputcejour}
                                placeholderinputtext={"Ce jour - texte libre - facultatif"}
                                titre={"Ce jour - texte libre :"}
                                champ={"champ"}
                                />
                            </div>


                           
                            </div>   

                            <div className="gauche100">
                                    <p className="titreitem">TOLERANCE EN POST-CURE :</p>
                    </div> 

                    <div className="gauchesection">
                        <div className="gauche100">
                        <Boutonexcluinput_zone
                            titre={"Tolérance clinique globale :"}
                            modeactivation={false}
                            nombredebouton={0}
                            variable={Toleranceglobale}
                            setParentvariable={setToleranceglobale}
                            reclicbouton={""}
                            placeholderinputtext={"zone de texte libre - facultatif"}
                            />
                        </div>
                    </div>


                    <p className="titreitem">Asthénie</p>

                    <div className="gauchesection">
                    <div className="gauche33">
                                <Bontonexclu
                                    titre={"Asthénie en post-cure :"}
                                    modeactivation={false}
                                    nombredebouton={5}
                                    variable={Asthenie}
                                    setParentvariable={setAsthenie}
                                    reclicbouton={""}
                                    placeholderinputtext={"entrée manuelle"}
                                    textb1={"Non majoré"}
                                    textb2={"asthénie G1"}
                                    textb3={"asthénie G2"}
                                    textb4={"asthénie G3"}
                                    textb5={"asthénie G4"}
                                    actb1={"Pas de majoration de l'asthénie au décours de la cure"}
                                    actb2={"Asthénie de grade 1"}
                                    actb3={"Asthénie de grade 2"}
                                    actb4={"Asthénie de grade 3"}
                                    actb5={"Asthénie de grade 4"}
                                    />
                                </div>

                            <div className="gauche33">
                            <Boutonexcluinput
                                titre={"Durée asthénie post-cure :"}
                                modeactivation={false}
                                nombredebouton={4}
                                variable={Dureasthenie}
                                setParentvariable={setDureasthenie}
                                reclicbouton={""}
                                placeholderinputtext={"ex : 5 jours"}
                                textb1={"1 jour"}
                                textb2={"2 jours"}
                                textb3={"3 jours"}
                                textb4={"peristante ce jour"}
                                actb1={"1 jour"}
                                actb2={"2 jours"}
                                actb3={"3 jours"}
                                actb4={"peristante ce jour"}
                                />
                            </div>

                            <div className="gauche33">
                            <Zonetexte
                                setParentvariableinput={setInputasthenie}
                                placeholderinputtext={"Asthénie - texte libre"}
                                titre={"Asthénie - texte libre :"}
                                champ={"champ"}
                                />
                            </div>


                            </div>


                            <p className="titreitem">Nausée et vomissement</p>


                            <div className="gauchesection">
                        <div className="gauche33">
                        <Bontonexclu
                            titre={"Nausée :"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Nausee}
                            setParentvariable={setNausee}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de nausée"}
                            textb2={"Nausée grade 1"}
                            textb3={"Nausée grade 2"}
                            textb4={"Nausée grade 3"}
                            actb1={"Pas de nausée"}
                            actb2={"Nausée grade 1"}
                            actb3={"Nausée grade 2"}
                            actb4={"Nausée grade 3"}
                            />
                        </div>

                        <div className="gauche16">
                        <Boutonexcluinput
                            titre={"Durée :"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Nauseeduree}
                            setParentvariable={setNauseeduree}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"1 jour"}
                            textb2={"2 jours"}
                            textb3={"3 jours"}
                            textb4={"persistante"}
                            actb1={"1 jour"}
                            actb2={"2 jours"}
                            actb3={"3 jours"}
                            actb4={"persistante ce jour"}
                            />
                        </div>


                        <div className="gauche33">
                        <Bontonexclu
                            titre={"Vomissement :"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Vomissement}
                            setParentvariable={setVomissement}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de vomissement"}
                            textb2={"vomissements grade 1"}
                            textb3={"vomissements grade 2"}
                            textb4={"vomissements grade 3"}
                            actb1={"Pas de vomissement"}
                            actb2={"vomissements grade 1"}
                            actb3={"vomissements grade 2"}
                            actb4={"vomissements grade 3"}
                            />
                        </div>

                        <div className="gauche16">
                        <Boutonexcluinput
                            titre={"Durée :"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Vomissementduree}
                            setParentvariable={setVomissementduree}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"1 jour"}
                            textb2={"2 jours"}
                            textb3={"3 jours"}
                            textb4={"persistant"}
                            actb1={"1 jour"}
                            actb2={"2 jours"}
                            actb3={"3 jours"}
                            actb4={"persistant ce jour"}
                            />
                        </div>

                        </div>

                        <div className="gauchesection">
                        <div className="gauche100">
                            <Zonetexte
                            setParentvariableinput={setNauseeinput}
                            placeholderinputtext={"taille modifiable en déplacant le coin inférieur gauche"}
                            titre={"Nausée et vomissement - texte libre :"}
                            champ={"champ"}
                            />
                        </div>
                        </div>  


                        <p className="titreitem">Diarrhée</p>



                        <div className="gauchesection">

                        <div className="gauche33">
                        <Boutonexcluinput
                            titre={"Diarrhée"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Diarrhee}
                            setParentvariable={setDiarrhee}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de diarrhée"}
                            textb2={"Diarrhée grade 1"}
                            textb3={"Diarrhée grade 2"}
                            textb4={"Diarrhée grade 3"}
                            actb1={"Pas de diarrhée"}
                            actb2={"Diarrhée grade 1"}
                            actb3={"Diarrhée grade 2"}
                            actb4={"Diarrhée grade 3"}
                            />
                        </div>

                        <div className="gauche33">
                            <Boutonexcluinput
                                titre={"Durée diarrhée post-cure :"}
                                modeactivation={false}
                                nombredebouton={4}
                                variable={Diarrheeduree }
                                setParentvariable={setDiarrheeduree }
                                reclicbouton={""}
                                placeholderinputtext={"ex : 5 jours"}
                                textb1={"1 jour"}
                                textb2={"2 jours"}
                                textb3={"3 jours"}
                                textb4={"peristante ce jour"}
                                actb1={"1 jour"}
                                actb2={"2 jours"}
                                actb3={"3 jours"}
                                actb4={"peristante ce jour"}
                                />
                            </div>

                            <div className="gauche33">
                            <Zonetexte
                                setParentvariableinput={setDiarrheeinput}
                                placeholderinputtext={"Diarrhée - texte libre"}
                                titre={"Diarrhée - texte libre :"}
                                champ={"champ"}
                                />
                            </div>

                    </div>

                    <p className="titreitem">Mucite</p>

                    <div className="gauchesection">

                        <div className="gauche50">
                        <Boutonexcluinput
                            titre={"Mucite"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Mucite}
                            setParentvariable={setMucite}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de mucite"}
                            textb2={"Mucite grade 1"}
                            textb3={"Mucite grade 2"}
                            textb4={"Mucite grade 3"}
                            actb1={"Pas de mucite"}
                            actb2={"Présence d'une mucite grade 1"}
                            actb3={"Présence d'une mucite grade 2"}
                            actb4={"Présence d'une mucite grade 3"}
                            />
                        </div>

                        <div className="gauche50">
                            <Zonetexte
                                setParentvariableinput={setMuciteinput}
                                placeholderinputtext={"Mucite - texte libre"}
                                titre={"Mucite :"}
                                champ={"champ"}
                                />
                            </div>

                        </div>

                        {/* <div className="gauchesection">

                        <div className="gauche100">
                        <Boutonexcluinput_zone
                            titre={"Sd mains-pieds"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Sdmainpieds }
                            setParentvariable={setSdmainpieds }
                            reclicbouton={""}
                            placeholderinputtext={"zone de texte libre modifiable"}
                            textb1={"Pas de syndrome mains-pieds"}
                            textb2={"Syndrome mains-pieds grade 1"}
                            textb3={"Syndrome mains-pieds grade 2"}
                            textb4={"Syndrome mains-pieds grade 3"}
                            actb1={"Pas de syndrome mains-pieds"}
                            actb2={"Présence d'un syndrome mains-pieds de grade 1"}
                            actb3={"Présence d'un syndrome mains-pieds de grade 2"}
                            actb4={"Présence d'un syndrome mains-pieds de grade 3"}
                            />
                        </div>

                        </div> */}

<p className="titreitem">Neuropathie</p>
<div className="gauchesection">
                        <div className="gauche33">
                        <Boutonexcluinput
                            titre={"Neuropathie"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Neuropathie}
                            setParentvariable={setNeuropathie}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de neuropathie"}
                            textb2={"Neuropathie grade 1"}
                            textb3={"Neuropathie grade 2"}
                            textb4={"Neuropathie grade 3"}
                            actb1={"Pas de neuropathie"}
                            actb2={"Neuropathie grade 1"}
                            actb3={"Neuropathie grade 2"}
                            actb4={"Neuropathie grade 3"}
                            />
                        </div>

                        <div className="gauche33">
                        <Boutonexcluinput
                            titre={"Durée post-cure :"}
                            modeactivation={false}
                            nombredebouton={4}
                            variable={Dureeneuropathie}
                            setParentvariable={setDureeneuropathie}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"1 jour"}
                            textb2={"2 jours"}
                            textb3={"3 jours"}
                            textb4={"en permanance"}
                            actb1={"1 jour"}
                            actb2={"2 jours"}
                            actb3={"3 jours"}
                            actb4={"en permanance"}
                            />
                        </div>

                        <div className="gauche33">
                            <Zonetexte
                                setParentvariableinput={setNeuropathieinput}
                                placeholderinputtext={"Neuropathie - texte libre"}
                                titre={"Neuropathie - texte libre :"}
                                champ={"champ"}
                                />
                            </div>

                        </div>

                        <p className="titreitem">Cutanée</p>

                        <div className="gauchesection">

                            <div className="gauche50">
                            <Bontonexclu
                                titre={"Eruption cutanée"}
                                modeactivation={false}
                                nombredebouton={1}
                                variable={Eruptioncutannee}
                                setParentvariable={setEruptioncutannee}
                                reclicbouton={""}
                                placeholderinputtext={""}
                                textb1={"Pas d'éruption cutanée"}
                                actb1={"Pas d'éruption cutanée"}
                                />
                            </div>

                            <div className="gauche50">
                                <Zonetexte
                                    setParentvariableinput={setEruptioncutanneeinput}
                                    placeholderinputtext={"Eruption cutanée - texte libre"}
                                    titre={"Eruption cutanée :"}
                                    champ={"champ"}
                                    />
                                </div>

                            </div>

                            <div className="gauchesection">

                    <div className="gauche50">
                    <Boutonexcluinput
                        titre={"Acné"}
                        modeactivation={false}
                        nombredebouton={4}
                        variable={Acnee}
                        setParentvariable={setAcnee}
                        reclicbouton={""}
                        textb1={"Pas d'acné"}
                        textb2={"Acné grade 1"}
                        textb3={"Acné grade 2"}
                        textb4={"Acné grade 3"}
                        actb1={"Pas d'acné"}
                        actb2={"Acné grade 1"}
                        actb3={"Acné grade 2"}
                        actb4={"Acné grade 3"}
                        placeholderinputtext={""}
                        />
                    </div>
                    <div className="gauche50">
                                <Zonetexte
                                    setParentvariableinput={setAcneeinput}
                                    placeholderinputtext={"Acnée - texte libre"}
                                    titre={"Acnée :"}
                                    champ={"champ"}
                                    />
                                </div>
                    </div>

                            <p className="titreitem">Pulmonaire</p>

                                <div className="gauchesection">

                                    <div className="gauche50">

                                    <Boutonexcluinput
                                    titre={"Dyspnée et toux"}
                                    modeactivation={false}
                                    nombredebouton={2}
                                    variable={Dyspnee}
                                    setParentvariable={setDyspnee}
                                    reclicbouton={""}
                                    textb1={"Absence de dyspnée et de toux"}
                                    actb1={"Pas de dyspnée, ni de toux"}
                                    textb2={"Dyspnée d'effort stable"}
                                    actb2={"Dyspnée d'effort stable"}
                                    placeholderinputtext={"zone de texte libre modifiable"}
                                    />

                                    </div>

                                    <div className="gauche50">
                                <Zonetexte
                                    setParentvariableinput={setDyspneeinput}
                                    placeholderinputtext={"Dyspnée - texte libre"}
                                    titre={"Dyspnée :"}
                                    champ={"champ"}
                                    />
                                </div>
                            </div>

                                    

                            <p className="titreitem">HTA</p>



                            <div className="gauchesection">

                            <div className="gauche50">
                            <Boutonexcluinput
                                titre={"HTA"}
                                modeactivation={false}
                                nombredebouton={2}
                                variable={HTA}
                                setParentvariable={setHTA}
                                reclicbouton={""}
                                placeholderinputtext={""}
                                textb1={"Pas d'HTA"}
                                textb2={"HTA contrôlé par le tt anti-hypertenseur"}
                                actb1={"Pas d'hypertension artérielle"}
                                actb2={"Hypertension artérielle contrôlée par le traitement anti-hypertenseur"}
                                />
                            </div>


                                <div className="gauche50">
                                <Zonetexte
                                    setParentvariableinput={setHTAinput}
                                    placeholderinputtext={"HTA - texte libre"}
                                    titre={"HTA - texte libre :"}
                                    champ={"champ"}
                                    />
                                </div>

                            </div>

                            <p className="titreitem">Protéinurie</p>



                                <div className="gauchesection">

                                <div className="gauche50">
                                <Boutonexcluinput
                                    titre={"Protéinurie"}
                                    modeactivation={false}
                                    nombredebouton={3}
                                    variable={Protéinurie}
                                    setParentvariable={setProtéinurie}
                                    reclicbouton={""}
                                    placeholderinputtext={""}
                                    textb1={"Pas de protéinurie"}
                                    textb2={"Protéinurie connue"}
                                    textb3={"Protéinurie nouvelle"}
                                    actb1={"Pas de protéinurie"}
                                    actb2={"Protéinurie connue"}
                                    actb3={"Protéinurie nouvelle"}
                                    />
                                </div>


                                    <div className="gauche50">
                                    <Zonetexte
                                        setParentvariableinput={setProtéinurieinout}
                                        placeholderinputtext={"Protéinurie - texte libre"}
                                        titre={"Protéinurie - texte libre :"}
                                        champ={"champ"}
                                        />
                                    </div>

                                </div>

                                <p className="titreitem">Saignements</p>



                        <div className="gauchesection">

                        <div className="gauche50">
                        <Boutonexcluinput
                            titre={"Saignements"}
                            modeactivation={false}
                            nombredebouton={2}
                            variable={Saignement}
                            setParentvariable={setSaignement}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de Saignement rapporté"}
                            textb2={"Epistaxis G1"}
                            actb1={"Pas de Saignement rapporté"}
                            actb2={"Epistaxis G1"}
                            />
                        </div>


                            <div className="gauche50">
                            <Zonetexte
                                setParentvariableinput={setSaignementinput}
                                placeholderinputtext={"Saignements - texte libre"}
                                titre={"Saignements - texte libre :"}
                                champ={"champ"}
                                />
                            </div>

                        </div>

                        <p className="titreitem">Autre(s)</p>

                    <div className="gauchesection">
                        <div className="gauche100">
                            <Zonetexte
                            setParentvariableinput={setInputCliniqueSpe}
                            placeholderinputtext={"taille modifiable en déplacant le coin inférieur gauche"}
                            titre={"Toxicité(s) autre(s) - texte libre :"}
                            champ={"champ"}
                            />
                        </div>
                        </div> 




                        <div className="gauche100">
                                    <p className="titreitem">GCSF :</p>
                    </div>  


                    {/* <div className="gauchesection">
                        <div className="gauche50">
                        <Boutonexcluinput
                            titre={"GCSF :"}
                            modeactivation={false}
                            nombredebouton={6}
                            variable={GCSF}
                            setParentvariable={setGCSF}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de prise GCSF"}
                            textb2={"Prise de GCSF pegylé"}
                            textb3={"NIVESTIM"}
                            textb4={"ZARZIO"}
                            textb5={"NEUPOGEN"}
                            textb6={"NEULASTA"}
                            actb1={"Pas de prise GCSF"}
                            actb2={"GRANOCYTE"}
                            actb3={"NIVESTIM"}
                            actb4={"ZARZIO"}
                            actb5={"NEUPOGEN"}
                            actb6={"NEULASTA"}
                            />       
                        </div>


                    </div> */}

                    <div className="gauchesection">
                        <div className="gauche100">
                        <Boutonexcluinput
                        titre={"GCSF"}
                        modeactivation={false}
                        nombredebouton={2}
                        variable={GCSFinput}
                        setParentvariable={setGCSFinput}
                        reclicbouton={""}
                        textb1={"Pas de GCSF au décours de la cure."}
                        actb1={"Pas de GCSF au décours de la cure."}
                        textb2={"GCSF prévu au décours de la cure."}
                        actb2={"GCSF prévu au décours de la cure."}
                        placeholderinputtext={"GCSF - texte libre :"}
                        />

                        </div>
                        </div> 

                        <div className="gauche100">
                                    <p className="titreitem">Transfusion :</p>
                    </div>  


                    {/* <div className="gauchesection">
                        <div className="gauche50">
                        <Boutonexcluinput
                            titre={"GCSF :"}
                            modeactivation={false}
                            nombredebouton={6}
                            variable={GCSF}
                            setParentvariable={setGCSF}
                            reclicbouton={""}
                            placeholderinputtext={""}
                            textb1={"Pas de prise GCSF"}
                            textb2={"Prise de GCSF pegylé"}
                            textb3={"NIVESTIM"}
                            textb4={"ZARZIO"}
                            textb5={"NEUPOGEN"}
                            textb6={"NEULASTA"}
                            actb1={"Pas de prise GCSF"}
                            actb2={"GRANOCYTE"}
                            actb3={"NIVESTIM"}
                            actb4={"ZARZIO"}
                            actb5={"NEUPOGEN"}
                            actb6={"NEULASTA"}
                            />       
                        </div>


                    </div> */}

                    <div className="gauchesection">
                        <div className="gauche100">
                        <Boutonexcluinput
                        titre={"Transfusion"}
                        modeactivation={false}
                        nombredebouton={2}
                        variable={Transfu}
                        setParentvariable={setTransfu}
                        reclicbouton={""}
                        textb1={"Pas de transfusion réalisée"}
                        textb2={"Transfusion réalisée : "}
                        actb1={"Pas de transfusion réalisée"}
                        actb2={"Transfusion réalisée : "}
                        placeholderinputtext={"Transfusion - texte libre :"}
                        />

                        </div>
                        </div> 

                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            </div>


            <div className="accordion"> 
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="titreacc">Examen clinique (cliquez ici pour fermer cette partie)</p>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
              
                  
                    <p className="titreitem">Constantes :</p> 
                            <div className="gauchesection">
                                <div className="gauche100">       
                                <Boutonexcluinput_zone
                                titre={""}
                                modeactivation={false}
                                nombredebouton={0}
                                variable={Cstinput}
                                setParentvariable={setCstinput}
                                reclicbouton={""}
                                placeholderinputtext={"zone de texte libre modifiable - facultatif"}
                                />               
                                </div>
                            </div> 


                            <p className="titreitem">Examen tumeur en place :</p> 

                            <div className="gauchesection">
                            <div className="gauche100">
                                <Zonetexte
                                setParentvariableinput={setTumeurenplace}
                                placeholderinputtext={"Examen tumeur en place - facultatif"}
                                titre={""}
                                champ={"champ"}
                                />
                            </div>
                            
                            </div> 


                            <p className="titreitem">Examen cardiologique :</p> 
                            <div className="gauchesection">

                        <div className="gauche100">
                        <Bontonexclu
                        titre={"Auscultation cardiaque"}
                        modeactivation={false}
                        nombredebouton={1}
                        variable={Cardioauscult}
                        setParentvariable={setCardioauscult}
                        reclicbouton={""}
                        textb1={"Bruit du coeur régulier, sans souffle surajouté"}
                        actb1={"bruit du coeur régulier, sans souffle surajouté"}
                        placeholderinputtext={"Zone de texte libre"}
                        />
                        </div>
                        </div>

                        <div className='gauchesection'>
                        <div className='gauche100'>
                        <Zonetexte
                                setParentvariableinput={setCardioinput}
                                placeholderinputtext={"Examen cardiologique - texte libre"}
                                titre={""}
                                champ={"champ"}
                                />
                        </div>
                        </div>  

                            <div className="gauchesection">

                            <p className="titreitem">Examen pulmonaire :</p> 
                            </div>

                            <div className="gauchesection">

                        <div className="gauche100">
                        <Bontonexclu
                        titre={"Auscultation pulmonaire"}
                        modeactivation={false}
                        nombredebouton={1}
                        variable={Respiauscult}
                        setParentvariable={setRespiauscult}
                        reclicbouton={""}
                        textb1={"Murmure vésiculaire bilatéral et symétrique, sans bruit surajouté"}
                        actb1={"murmure vésiculaire bilatéral et symétrique, sans bruit surajouté"}
                        placeholderinputtext={"Zone de texte libre"}
                        />
                        </div>
                        </div>

                        <div className='gauchesection'>
                        <div className='gauche100'>
                        <Zonetexte
                                setParentvariableinput={setRespiinput}
                                placeholderinputtext={"Examen pulmonaire - texte libre"}
                                titre={""}
                                champ={"champ"}
                                />
                        </div>
                        </div>  

        <div className="gauchesection">
        <p className="titreitem">Examen abdominal :</p> 
        </div>



                        <div className="gauchesection">

                <div className="gauche100">
                <Bontonexclu
                titre={"Examen abdominal"}
                modeactivation={false}
                nombredebouton={1}
                variable={Abdopal}
                setParentvariable={setAbdopal}
                reclicbouton={""}
                textb1={"Abdomen souple, dépressible, indolore"}
                actb1={"abdomen souple, dépressible, indolore"}
                placeholderinputtext={"Zone de texte libre"}
                />
                </div>
                </div>

                <div className='gauchesection'>
                <div className='gauche100'>
                <Zonetexte
                        setParentvariableinput={setAbdoinput}
                        placeholderinputtext={"Examen abdominal - texte libre"}
                        titre={""}
                        champ={"champ"}
                        />
                </div>
                </div> 

        <div className="gauchesection">
        <p className="titreitem">Examen autre  :</p> 
        </div>

        <div className="gauchesection">
        <div className="gauche100">
            <Zonetexte
            setParentvariableinput={setExamautreinout}
            placeholderinputtext={"examen autre  -  texte libre"}
            titre={""}
            champ={"champ"}
            />
        </div>
        </div> 

                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            </div>


            <div className="accordion"> 
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="titreacc">Biologie</p>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>

                    <div className="gauchesection">
                        <div className="gauche100">
                        <Boutonexcluinput_zone
                            titre={"Commentaire à propos de la biologie (facultatif) :"}
                            modeactivation={false}
                            variable={Biovalidation}
                            setParentvariable={setBiovalidation}
                            nombredebouton={1}
                            reclicbouton={""}
                            placeholderinputtext={"zone de texte libre modifiable - facultatif"}
                            textb1={"Biologie permettant la réalisation du traitement"}
                            actb1={"Biologie permettant la réalisation du traitement"}
                            />
                        </div>
                    </div>

                    <div className='gauchesection'>
                    <span className='vertical-text_bio'>Vous pouvez utiliser la touche TABULATION pour changer de case</span>
                    </div>

                    <div className="gauchesection">


                            <div className="gauche14">
                                <Inputbiotitre
                                modeactivation={false}
                                texte={"NFS"}                              
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"Hb "}
                                setParentvariableinput={setHb}
                                placeholderinputtext={"g/dl"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"PNN "}
                                setParentvariableinput={setPnn}
                                placeholderinputtext={"G/l"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"Pl "}
                                setParentvariableinput={setPl}
                                placeholderinputtext={"en G/l"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>


                            </div>  




                            <div className="gauchesection">


                        <div className="gauche14">
                            <Inputbiotitre
                            modeactivation={false}
                            texte={"Rénal"}                              
                            />
                        </div>

                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"Créat "}
                            setParentvariableinput={setCr}
                            placeholderinputtext={"µmol/l"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>

                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"Na "}
                            setParentvariableinput={setNa}
                            placeholderinputtext={"mmol/l"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>

                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"K "}
                            setParentvariableinput={setK}
                            placeholderinputtext={"mmol/l"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>

                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"Cac "}
                            setParentvariableinput={setCac}
                            placeholderinputtext={"mmol/l"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>


                        </div>  


                        
                        <div className="gauchesection">


                            <div className="gauche14">
                                <Inputbiotitre
                                modeactivation={false}
                                texte={"Hépatique"}                              
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"ASAT "}
                                setParentvariableinput={setAsat}
                                placeholderinputtext={"en N"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"ALAT "}
                                setParentvariableinput={setAlat}
                                placeholderinputtext={"en N"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"BiliT"}
                                setParentvariableinput={setBilit}
                                placeholderinputtext={"en µmol/l"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"BiliC "}
                                setParentvariableinput={setBilic}
                                placeholderinputtext={"en µmol/l"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>


                            </div>  

                            <div className="gauchesection">


                            <div className="gauche14">
                                <Inputbiotitre
                                modeactivation={false}
                                texte={"A.I."}                              
                                />
                            </div>

                            <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"TSH "}
                            setParentvariableinput={setTSH}
                            placeholderinputtext={"en mUI/l"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>

                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"T4 "}
                            setParentvariableinput={setT4}
                            placeholderinputtext={"en pmol/l"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>

                            <div className="gauche20">
                                <Inputbio
                                modeactivation={false}
                                texte={"Cortisol"}
                                setParentvariableinput={setCortisol}
                                placeholderinputtext={"en pmol/l"}
                                type={"text"}
                                taille={"date1"}
                                />
                            </div>

                            </div>  


                        

                        <div className="gauchesection">


                        <div className="gauche14">
                            <Inputbiotitre
                            modeactivation={false}
                            texte={"Coeur"}                              
                            />
                        </div>

                        
                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"BNP "}
                            setParentvariableinput={setBNP}
                            placeholderinputtext={"en pg/mL"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>

                        <div className="gauche20">
                            <Inputbio
                            modeactivation={false}
                            texte={"Tropo "}
                            setParentvariableinput={setTropo}
                            placeholderinputtext={"ng/L"}
                            type={"text"}
                            taille={"date1"}
                            />
                        </div>


                        </div>  

                        <div className="gauchesection">
                        <div className="gauche100">
                        <Zonetexte
                        setParentvariableinput={setInputobservbio}
                        placeholderinputtext={"biologie - texte libre"}
                        titre={""}
                        champ={"champ"}
                        />
                        </div>
                        </div> 
                        

 

                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            </div>

            <div className="accordion"> 
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="titreacc">Conclusion</p>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>

 

                    <div className="gauchesection_conclu">
                    <span className='vertical-text'>Zone modifiable</span>
                        <div className="gauche100">
                        
                        <Boutonexcluinput_zone_long
                        titre={"Conclusion de cette cure :"}
                        modeactivation={false}
                        nombredebouton={1}
                        variable={Validation}
                        setParentvariable={setValidation}
                        reclicbouton={""}
                        placeholderinputtext={"zone de texte libre modifiable"}
                        textb1={"Validation du"+" "+Cycle+" "+Chimio}
                        actb1={"Validation du"+" "+Cycle+" "+Chimio}
                        />
                        </div>
                    </div>

                    <div className="gauchesection">

                            <div className="gauche100">
                                                        <Inputbio
                                    modeactivation={false}
                                    texte={"Date de l'administration de cette cure :"}
                                    setParentvariableinput={setDatecure}
                                    placeholderinputtext={""}
                                    type={"date"}
                                    taille={"date1"}
                                    />   
                            </div>
                        </div>




 


                        {/* <p className="titreitem">Suite de la prise en charge :</p>  */}

                        <p className="titrebouton_2">Suite de la prise en charge :</p>
                        <div className="gauchesection">
                            <div className="gauche100">
                                                        <Inputbio
                                    modeactivation={false}
                                    texte={"Date de la réinduction :"}
                                    setParentvariableinput={setProchainecuredate}
                                    placeholderinputtext={""}
                                    type={"date"}
                                    taille={"date1"}
                                    />   
                            </div>
                        </div>

                        <div className="gauchesection">
                            <div className="gauche100">
                                                        <Inputbio
                                    modeactivation={false}
                                    texte={"Prochains examens de réévaluation :"}
                                    setParentvariableinput={setExamreevaluation}
                                    placeholderinputtext={""}
                                    type={"text"}
                                    taille={"text1"}
                                    />   
                            </div>
                        </div>

                        {/* <div className="gauchesection">

                        <div className="gauche50">
                            <p className="texteinputbio">Prochains examens de réévaluation :</p>
                        </div>
                        

                        <div className="gauche66">
                            <Zonetexte
                            setParentvariableinput={setExamreevaluation}
                            placeholderinputtext={"Prochaine réévaluation  -  taille modifiable en déplacant le coin inférieur gauche"}
                            titre={""}
                            />
                        </div>
                        </div> */}

                        <div className="gauchesection">
                            <div className="gauche100">
                                                        <Inputbio
                                    modeactivation={false}
                                    texte={"Date de la prochaine consultation avec le référent :"}
                                    setParentvariableinput={setProchainecsreferent}
                                    placeholderinputtext={""}
                                    type={"date"}
                                    taille={"date1"}
                                    />   
                            </div>
                        </div>

                        <div className="gauchesection">
                        <div className="gauche100">
                            <Zonetexte
                            setParentvariableinput={setSuiteinput}
                            placeholderinputtext={"Suite de la prise en charge - texte libre"}
                            titre={""}
                            champ={"champ"}
                            />
                        </div>
                        </div>

                        <p className="titreitem_2">Dose(s) reçue(s) :</p> 

                    <span className='vertical-text_dose'>Vous pouvez utiliser la touche TABULATION pour changer de case</span>


<div className="gauchesection">
    <div className="gauche25">
    <Aff_chimio
        titre={"Produit"}
        modeactivation={false}
        nombredebouton={0}
        variable={Produit1}
        setParentvariable={setProduit1}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche25">
    <Aff_chimio
        titre={"Dose"}
        modeactivation={false}
        nombredebouton={0}
        variable={Doseprevu1}
        setParentvariable={setDoseprevu1}
        reclicbouton={""}
        placeholderinputtext={"/m² /kg AUC"}
        />
    </div>

    <div className="gauche16">
    <Aff_chimio
        titre={"Dose totale"}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosetotale1}
        setParentvariable={setDosetotale1}
        reclicbouton={""}
        placeholderinputtext={"ex : 55mg"}
        />
    </div>

    <div className="gauche33">
    <Aff_chimio
        titre={"Commentaire"}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosecomm1}
        setParentvariable={setDosecomm1}
        reclicbouton={""}
        placeholderinputtext={"facultatif"}
        />
    </div>
</div>


{/* 2EME PRODUIT */}


<div className="gauchesection">
    <div className="gauche25">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Produit2}
        setParentvariable={setProduit2}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche25">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Doseprevu2}
        setParentvariable={setDoseprevu2}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche16">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosetotale2}
        setParentvariable={setDosetotale2}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche33">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosecomm2}
        setParentvariable={setDosecomm2}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>
</div>



                        {/* 3EME PRODUIT */}


                        <div className="gauchesection">
    <div className="gauche25">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Produit3}
        setParentvariable={setProduit3}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche25">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Doseprevu3}
        setParentvariable={setDoseprevu3}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche16">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosetotale3}
        setParentvariable={setDosetotale3}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche33">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosecomm3}
        setParentvariable={setDosecomm3}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>
</div>


                        {/* 4EME PRODUIT */}


                        <div className="gauchesection">
    <div className="gauche25">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Produit4}
        setParentvariable={setProduit4}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche25">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Doseprevu4}
        setParentvariable={setDoseprevu4}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche16">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosetotale4}
        setParentvariable={setDosetotale4}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>

    <div className="gauche33">
    <Aff_chimio
        titre={""}
        modeactivation={false}
        nombredebouton={0}
        variable={Dosecomm4}
        setParentvariable={setDosecomm4}
        reclicbouton={""}
        placeholderinputtext={""}
        />
    </div>
</div>

                <div className="gauchesection">
                        <div className="gauche100">
                            <Zonetexte
                            setParentvariableinput={setDoseinput}
                            placeholderinputtext={"Dose - texte libre  -  taille modifiable en déplacant le coin inférieur gauche"}
                            titre={""}
                            champ={"champ"}
                            />
                        </div>
                        </div> 


                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            </div>
            {/* <span>Envoyez nous vos remarques :  </span> */}

            </div>


    </div>





        <div className="droite">
        <div className="droiterectangle">

                        


<div className="barreduhaut">




    <p className="titredroite">Rendu de l'observation à copier (non modifiable dans cette fenêtre) </p>

    <div onClick={copyToClipboard} style={{ cursor: 'pointer', marginLeft: '15px'}}>
      <img src={Image_copier} alt="Copier" width="35" height="35" />
    </div>   

</div>


<div className="zonerendutexte">
<div ref={jsxContentRef}>

<span style={{ fontWeight: 'bold', textDecoration: 'underline' }} >{(Cycle!=="" | Chimio!=="") ? "Indication :" : null}</span>
{(Cycle!=="" | Chimio!=="") ? <br/> : null}
{Cycle}
{(Cycle!=="" | Chimio!=="") ? " " : null}
{Chimio}
{(Cycle!=="" | Chimio!=="") ? <br/> : null}
{(Cycle!=="" | Chimio!=="") ? <br/> : null}

<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{Exampretherapeutique!=="" ? "Examen(s) pré-thérapeutique :" : null}</span>
{Exampretherapeutique!=="" ? <br/> : null}
{Exampretherapeutique !=="" ? <NewLineToBr>{Exampretherapeutique}</NewLineToBr> : null}
{Exampretherapeutique!=="" ? <br/> : null}



<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{(Evtinterinput !=="" | Evtintercu !=="") ? "Evènement intercurrent :" : null}</span>
{(Evtinterinput !=="" | Evtintercu !=="") ? <br/> : null}
{Evtintercu}
{(Evtintercu !=="") ? <br/> : null}

{Evtinterinput !=="" ? <NewLineToBr>{Evtinterinput}</NewLineToBr> : null}
{(Evtinterinput==="" & Evtintercu!=="" ) ? <br/> : null}
{(Evtinterinput =="" & Evtintercu !=="") ? null : <br/>}


<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{(GCSFinput!==""|Transfu!==""|Saignement!==""|Saignementinput!==""|Protéinurie!==""|Protéinurieinout!==""|HTA!==""|HTAinput!==""|Acnee!==""|Acneeinput!==""|Eruptioncutannee !==""|Eruptioncutanneeinput!==""|Toleranceglobale!==""|Mucite!==""| Muciteinput!==""| Nausee !==""|Vomissement!==""| Diarrhee!==""|Diarrheeinput!==""| Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="" | Toleranceglobale!==""|Poids !=="" | Ps!=="" | Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="" | Inputcejour!=="") ? "Information clinique et tolérance :" : null}</span>
{(Poids!=="" | Ps !=="") ? <br/> : null}
{(Ps!=="") ? <span style={{textDecoration: 'underline' }}>Ce jour</span>: null}{(Ps!=="") ? " : ": null}
{(Ps!=="") ? "le PS est évalué à  ": null}{(Ps!=="") ? Ps: null}{(Ps!=="") ? ". ": null}{(Poids!=="") ? "Le poids est de ": null}{(Poids!=="") ? Poids: null}{(Poids!=="") ? " kg": null}{(Poidsvariation!=="") ? " (": null}{(Poidsvariation!=="") ? Poidsvariation: null}{(Poidsvariation!=="") ? ")": null}{(Poids!=="") ? ". ": null}
{(Poids!=="" | Ps !=="") & (Alimentationappetit!=="" | Alimentationcno!=="") ? <br/> : null}
{(Alimentationappetit!=="" | Alimentationcno!=="") ? ". ": null}

{Inputcejour !=="" ? <br/> : null}
{Inputcejour !=="" ? <NewLineToBr>{Inputcejour}</NewLineToBr> : null}

{((GCSFinput!==""|Transfu!==""|Saignement!==""|Saignementinput!==""|Protéinurie!==""|Protéinurieinout!==""|HTA!==""|HTAinput!==""|Acnee!==""|Acneeinput!==""|Eruptioncutannee !==""|Eruptioncutanneeinput!==""|Toleranceglobale!==""|Mucite!==""| Muciteinput!==""| Nausee !==""|Vomissement!==""| Diarrhee!==""|Diarrheeinput!==""| Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="" | Toleranceglobale!==""|Poids !=="" | Ps!=="" | Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="") & (Inputcejour ==="")) ? <br/> : null}
{(GCSFinput!==""|Transfu!==""|Saignement!==""|Saignementinput!==""|Protéinurie!==""|Protéinurieinout!==""|HTA!==""|HTAinput!==""|Acnee!==""|Acneeinput!==""|Eruptioncutannee !==""|Eruptioncutanneeinput!==""|Toleranceglobale!==""|Mucite!==""| Muciteinput!==""| Nausee !==""|Vomissement!==""| Diarrhee!==""|Diarrheeinput!==""| Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="" | Toleranceglobale!==""|Poids !=="" | Ps!=="" | Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="" | Inputcejour!=="") ? <br/> : null}


{/* STRUC COND */}
{(GCSFinput!==""|Transfu!==""|Saignement!==""|Saignementinput!==""|Protéinurie!==""|Protéinurieinout!==""|HTA!==""|HTAinput!==""|Acnee!==""|Acneeinput!==""|Eruptioncutannee !==""|Eruptioncutanneeinput!==""|Toleranceglobale!==""|Mucite!==""| Muciteinput!==""| Nausee !==""|Vomissement!==""| Diarrhee!==""|Diarrheeinput!==""| Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="") ? <span style={{textDecoration: 'underline' }}>Tolérance de la précédente cure</span> : null}
{(GCSFinput!==""|Transfu!==""|Saignement!==""|Saignementinput!==""|Protéinurie!==""|Protéinurieinout!==""|HTA!==""|HTAinput!==""|Acnee!==""|Acneeinput!==""|Eruptioncutannee !==""|Eruptioncutanneeinput!==""|Toleranceglobale!==""|Mucite!==""| Muciteinput!==""| Nausee !==""|Vomissement!==""| Diarrhee!==""|Diarrheeinput!==""| Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="") ? " : " : null}{(GCSFinput!==""|Transfu!==""|Saignement!==""|Saignementinput!==""|Protéinurie!==""|Protéinurieinout!==""|HTA!==""|HTAinput!==""|Acnee!==""|Acneeinput!==""|Eruptioncutannee !==""|Eruptioncutanneeinput!==""|Toleranceglobale!==""|Mucite!==""| Muciteinput!==""| Nausee !==""|Vomissement!==""| Diarrhee!==""|Diarrheeinput!==""| Asthenie!=="" | Neuropathie !=="" | Toxunguéale !=="" | Alocepie !=="") ? <br/> : null}

{Toleranceglobale}
{Toleranceglobale!=="" ? <br/> : null}

{Asthenie}{(Asthenie !=="" & Dureasthenie !=="peristante ce jour" & Dureasthenie !=="") ? " persistante pendant " : null}{(Asthenie !=="" & Dureasthenie !=="peristante ce jour" & Dureasthenie !=="") ? Dureasthenie : null}{(Dureasthenie !=="" & Dureasthenie ==="peristante ce jour") ? " toujours persistante ce jour" : null}{(Asthenie !=="") ? ". " : null}
{Inputasthenie !=="" ? <NewLineToBr>{Inputasthenie}</NewLineToBr> : null}
{Asthenie!=="" & Inputasthenie===""? <br/> : null}


{Nausee ==="Pas de nausée" & Vomissement ==="Pas de vomissement" ? "Pas de nausée, ni de vomissement" : null}
{(Nausee ==="Pas de nausée" & Vomissement ==="Pas de vomissement") ? null : Nausee}{(Nausee !=="" & Nauseeduree  !=="peristante ce jour" & Nauseeduree  !=="") ? " persistante pendant " : null}{(Nausee !=="" & Nauseeduree  !=="peristante ce jour" & Nauseeduree  !=="") ? Nauseeduree  : null}{(Nauseeduree  !=="" & Nauseeduree  ==="peristante ce jour") ? " toujours persistante ce jour" : null}
{!(Nausee ==="Pas de nausée" & Vomissement ==="Pas de vomissement")&Vomissement==="Pas de vomissement" & Nausee!=="" ? ", sans vomissement associé" : null }
{!(Nausee ==="Pas de nausée" & Vomissement ==="Pas de vomissement")&Vomissement!=="Pas de vomissement" & Vomissement!=="" & Nausee!=="" ? ",  associée à des " : null }
{!(Nausee ==="Pas de nausée" & Vomissement ==="Pas de vomissement")&Vomissement!=="Pas de vomissement" & Vomissement!=="" & Nausee!=="" ? Vomissement : null }

{(Vomissement  !=="" & Vomissementduree   !=="peristants ce jour" & Vomissementduree   !=="") ? " persistants pendant " : null}
{(Vomissement  !=="" & Vomissementduree   !=="peristants ce jour" & Vomissementduree   !=="") ? Vomissementduree   : null}{(Vomissementduree   !=="" & Vomissementduree   ==="peristants ce jour") ? " toujours persistants ce jour" : null}
{(Vomissement  !=="" | Nausee!=="") ? ". " : null}
{Nauseeinput !=="" ? <NewLineToBr>{Nauseeinput}</NewLineToBr> : null}
{(Nausee !=="" | Vomissement !=="")&Nauseeinput==="" ? <br/> : null}

{Diarrhee}{(Diarrhee  !=="" & Diarrheeduree  !=="peristante ce jour" & Diarrheeduree  !=="") ? " persistante pendant " : null}{(Diarrhee  !=="" & Diarrheeduree  !=="peristante ce jour" & Diarrheeduree  !=="") ? Diarrheeduree  : null}{(Diarrheeduree  !=="" & Diarrheeduree  ==="peristante ce jour") ? " toujours persistante ce jour" : null}{(Diarrhee  !=="") ? ". " : null}
{Diarrheeinput  !=="" ? <NewLineToBr>{Diarrheeinput }</NewLineToBr> : null}
{Diarrhee!=="" & Diarrheeinput===""? <br/> : null}

{Mucite }{(Mucite   !=="") ? ". " : null}
{Muciteinput   !=="" ? <NewLineToBr>{Muciteinput  }</NewLineToBr> : null}
{Mucite !=="" & Muciteinput ===""? <br/> : null}

{Neuropathie}{(Neuropathie !=="" & Dureeneuropathie !=="en permanance" & Dureeneuropathie !=="") ? " persistant pendant " : null}{(Neuropathie !=="" & Dureeneuropathie !=="en permanance" & Dureeneuropathie !=="") ? Dureeneuropathie : null}{(Dureeneuropathie !=="" & Dureeneuropathie ==="en permanance") ? " persistant en permanance" : null}{(Neuropathie !=="") ? ". " : null}
{Neuropathieinput !=="" ? <NewLineToBr>{Neuropathieinput}</NewLineToBr> : null}
{Neuropathie!=="" & Neuropathieinput===""? <br/> : null}

{Eruptioncutannee }{(Eruptioncutannee   !=="") ? ". " : null}
{Eruptioncutanneeinput   !=="" ? <NewLineToBr>{Eruptioncutanneeinput  }</NewLineToBr> : null}
{Eruptioncutannee !=="" & Eruptioncutanneeinput ===""? <br/> : null}

{Acnee }{(Acnee   !=="") ? ". " : null}
{Acneeinput   !=="" ? <NewLineToBr>{Acneeinput  }</NewLineToBr> : null}
{Acnee !=="" & Acneeinput ===""? <br/> : null}

{Dyspnee }{(Dyspnee   !=="") ? ". " : null}
{Dyspneeinput   !=="" ? <NewLineToBr>{Dyspneeinput  }</NewLineToBr> : null}
{Dyspnee !=="" & Dyspneeinput ===""? <br/> : null}

{HTA }{(HTA   !=="") ? ". " : null}
{HTAinput   !=="" ? <NewLineToBr>{HTAinput  }</NewLineToBr> : null}
{HTA !=="" & HTAinput ===""? <br/> : null}

{Protéinurie }{(Protéinurie   !=="") ? ". " : null}
{Protéinurieinout   !=="" ? <NewLineToBr>{Protéinurieinout  }</NewLineToBr> : null}
{Protéinurie !=="" & Protéinurieinout ===""? <br/> : null}

{Saignement }{(Saignement   !=="") ? ". " : null}
{Saignementinput   !=="" ? <NewLineToBr>{Saignementinput  }</NewLineToBr> : null}
{Saignement !=="" & Saignementinput ===""? <br/> : null}

{Toxunguéale}
{Inputtoxunguéale !=="" ? <NewLineToBr>{Inputtoxunguéale}</NewLineToBr> : null}
{Toxunguéale!=="" & Inputtoxunguéale==="" ? <br/> : null}
{Alocepie}
{Alopecieinput !=="" ? <NewLineToBr>{Alopecieinput}</NewLineToBr> : null}
{Alocepie!=="" & Alopecieinput==="" ? <br/> : null}


{InputCliniqueSpe !=="" ? <NewLineToBr>{InputCliniqueSpe}</NewLineToBr> : null}

 <br/> 
{GCSFinput  !=="" ? <NewLineToBr>{GCSFinput }</NewLineToBr> : null}
{(GCSFinput!=="" & Transfu=="") ? <br/> : null}

{Transfu  !=="" ? <NewLineToBr>{Transfu }</NewLineToBr> : null}
{(Transfu!=="") ? <br/> : null}

{/* FIN MODIF */}


<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{(Cstinput !=="") ? "Constante :" : null}</span>
{(Cstinput !=="") ? <br/> : null}
{Cstinput !=="" ? <NewLineToBr>{Cstinput}</NewLineToBr> : null}
{(Cstinput !=="") ? <br/> : null}



<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{(Tumeurenplace !=="" | Cardioauscult!=="" | Cardisp!=="" | Cardioinput !=="" | Respiauscult !=="" | Respiinput !=="" | Abdopal !=="" | Abdoinput !==""| Examautreinout !=="") ? "Examen clinique :" : null}</span>
{Tumeurenplace!=="" ? <br/> : null}
{Tumeurenplace!=="" ? <span style={{textDecoration: 'underline' }}>Tumeur en place : </span>: null}{Tumeurenplace}{Tumeurenplace!=="" ? "." : null}
{Cardioauscult!=="" | Cardisp!=="" | Cardioinput !==""  ? <br/> : null}
{Cardioauscult!=="" | Cardisp!=="" | Cardioinput !==""  ? <span style={{textDecoration: 'underline' }}>Sur le plan cardiologique : </span>: null}{Cardioauscult}{Cardisp!=="" & Cardioauscult!=="" ? ". " : null}{Cardisp}{Cardioauscult!=="" | Cardisp!=="" ? ". " : null}
{Cardioinput !=="" ? <NewLineToBr>{Cardioinput}</NewLineToBr> : null}
{(Respiauscult!=="" | Respiinput!=="") & Cardioinput===""  ? <br/> : null}
{Respiauscult!=="" | Respiinput!=="" ? <span style={{textDecoration: 'underline' }}>Sur le plan respiratoire : </span>: null}{Respiauscult}{Respiauscult!=="" | Respiinput!=="" ? ". " : null}
{Respiinput !=="" ? <NewLineToBr>{Respiinput}</NewLineToBr> : null}
{(Abdopal!=="" | Abdoinput!=="") & Respiinput==="" ? <br/> : null}
{Abdopal!=="" | Abdoinput!=="" ? <span style={{textDecoration: 'underline' }}>Sur le plan abdominal : </span>: null}{Abdopal}{Abdopal!=="" | Abdoinput!=="" ? ". " : null}
{Abdoinput !=="" ? <NewLineToBr>{Abdoinput}</NewLineToBr> : null}
{Examautreinout !=="" & Abdoinput==="" ? <br/> : null}
{Examautreinout !=="" ? <NewLineToBr>{Examautreinout}</NewLineToBr> : null}

{((Tumeurenplace !=="" | Cardioauscult!=="" | Cardisp!=="" | Cardioinput !=="" | Respiauscult !=="" | Respiinput !=="" | Abdopal !=="" | Abdoinput !==""| Examautreinout !=="") & Examautreinout==="") ? <br/> : null}
{(Tumeurenplace !=="" | Cardioauscult!=="" | Cardisp!=="" | Cardioinput !=="" | Respiauscult !=="" | Respiinput !=="" | Abdopal !=="" | Abdoinput !==""| Examautreinout !=="") ? <br/> : null}


<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{(Biovalidation !=="" | Hb!=="" | Cac !=="" | Pnn!=="" | Pl !=="" | Cr !=="" | Na !=="" | K !=="" | Alat !==""| Asat !=="" | Bilit !=="" | Bilic !=="" | TSH!==""|T4!==""|Cortisol!==""|BNP!==""|Tropo!==""| Inputobservbio !=="" ) ? "Biologie :" : null}</span>
{(Biovalidation !=="" | Hb!=="" | Cac !=="" | Pnn!=="" | Pl !=="" | Cr !=="" | Na !=="" | K !=="" | Alat !==""| Asat !=="" | Bilit !=="" | Bilic !=="" | TSH!==""|T4!==""|Cortisol!==""|BNP!==""|Tropo!==""| Inputobservbio !=="" ) ? <br/> : null}
{Biovalidation}{Biovalidation !=="" ? "." : null}
{((Biovalidation !=="") & (Hb!=="" | Cac !=="" | Pnn!=="" | Pl !=="" | Cr !=="" | Na !=="" | K !=="" | Alat !==""| Asat !=="" | Bilit !=="" | Bilic !=="" | TSH!==""|T4!==""|Cortisol!==""|BNP!==""|Tropo!==""| Inputobservbio !=="" ))  ? <br/> : null}

{Hb!=="" | Pnn!=="" | Pl !=="" ? <span style={{textDecoration: 'underline' }}>NFS :</span> : null}{Hb!=="" | Pnn!=="" | Pl !=="" ? " " : null}
{Hb !=="" ? "Hb : " : null}{Hb}{Hb !=="" ? " g/dl" : null}
{Pnn!=="" & Hb!=="" ? ", " : null}
{Pnn !=="" ? "PNN : " : null}{Pnn}{Pnn !=="" ? " G/l" : null}
{Pl!=="" & (Hb!=="" | Pnn!=="") ? ", " : null}
{Pl !=="" ? "Plaquette : " : null}{Pl}{Pl !=="" ? " G/l" : null}
{(Cr !=="" | Cac !=="" | Na !=="" | K !=="") & (Hb!=="" | Pnn!=="" | Pl !=="") ? <br/> : null}
{Cr !=="" | Cac !=="" | Na !=="" | K !==""  ? <span style={{textDecoration: 'underline' }}>Bilan rénal :</span> : null}{Cr !=="" | Cac !=="" | Na !=="" | K !=="" ? " " : null}
{Cr !=="" ? "Créatinémie : " : null}{Cr}{Cr !=="" ? " µmol/l" : null}
{Na!=="" & Cr!=="" ? ", " : null}
{Na !=="" ? "Na : " : null}{Na}{Na !=="" ? " mmol/l" : null}
{K!=="" & (Na!=="" | Cr!=="") ? ", " : null}
{K !=="" ? "K : " : null}{K}{K !=="" ? " mmol/l" : null}
{Cac!=="" & (Cr!=="" | K!=="" | Na!=="") ? ", " : null}
{Cac !=="" ? "Ca corrigée : " : null}{Cac}{Cac !=="" ? " mmol/l" : null}
{(Alat !=="" | Asat !=="" | Bilic !=="" | Bilit !=="") & (Cr !=="" | Cac !=="" | Na !=="" | K !=="" | Hb!=="" | Pnn!=="" | Pl !=="") ? <br/> : null}
{Asat !=="" | Alat !=="" | Bilic !=="" | Bilit !==""  ? <span style={{textDecoration: 'underline' }}>Bilan hépatique :</span> : null}{Asat !=="" | Alat !=="" | Bilic !=="" | Bilit !=="" ? " " : null}
{Asat !=="" ? "ASAT : " : null}{Asat}{Asat !=="" ? " N" : null}
{Alat!=="" & Asat!=="" ? ", " : null}
{Alat !=="" ? "ALAT : " : null}{Alat}{Alat !=="" ? " N" : null}
{Bilit!=="" & (Alat!=="" | Asat!=="") ? ", " : null}
{Bilit !=="" ? "Bilirubine totale : " : null}{Bilit}{Bilit !=="" ? " µmol/l" : null}
{Bilic!=="" & (Alat!=="" | Asat!=="" | Bilit!=="") ? ", " : null}
{Bilic !=="" ? "Bilirubine conjugée : " : null}{Bilic}{Bilic !=="" ? " µmol/l" : null}

{(Alat !=="" | Asat !=="" | Bilic !=="" | Bilit !=="" | Cr !=="" | Cac !=="" | Na !=="" | K !=="" | Hb!=="" | Pnn!=="" | Pl !=="") & (TSH !=="" | T4 !=="") ? <br/> : null}
{TSH !=="" | T4 !=="" ? <span style={{textDecoration: 'underline' }}>Autre :</span> : null}{TSH !=="" | T4 !=="" ? " " : null}
{TSH !=="" ? "TSH : " : null}{TSH}{TSH !=="" ? " mUI/l" : null}
{TSH!=="" & T4!=="" ? ", " : null}
{T4 !=="" ? "T4 : " : null}{T4}{T4 !=="" ? " pmol/l" : null}
{T4!=="" & Cortisol!=="" ? ", " : null}
{Cortisol !=="" ? "Cortisol : " : null}{Cortisol}{Cortisol !=="" ? " pmol/l" : null}
{Cortisol!=="" & BNP!=="" ? ", " : null}
{BNP !=="" ? "BNP : " : null}{BNP}{BNP !=="" ? " pmol/l" : null}
{BNP!=="" & Tropo!=="" ? ", " : null}
{Tropo !=="" ? "Troponine : " : null}{Tropo}{BNP !=="" ? " ng/l" : null}


{Inputobservbio!=="" ? <br/> : null}
{Inputobservbio !=="" ? <NewLineToBr>{Inputobservbio}</NewLineToBr> : null}

{(Biovalidation !=="" | Hb!=="" | Cac !=="" | Pnn!=="" | Pl !=="" | Cr !=="" | Na !=="" | K !=="" | Alat !==""| Asat !=="" | Bilit !=="" | Bilic !=="" | TSH!==""|T4!==""|Cortisol!==""|BNP!==""|Tropo!=="") & Inputobservbio==="" ? <br/> : null}
{(Biovalidation !=="" | Hb!=="" | Cac !=="" | Pnn!=="" | Pl !=="" | Cr !=="" | Na !=="" | K !=="" | Alat !==""| Asat !=="" | Bilit !=="" | Bilic !=="" | TSH!==""|T4!==""|Cortisol!==""|BNP!==""|Tropo!==""| Inputobservbio !=="" ) ? <br/> : null}


<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{(Validation !=="" | Validationinput!=="" | Datecure!=="") ? "Conclusion :" : null}</span>
{(Validation !=="" | Validationinput!=="" | Datecure!=="") ? <br/> : null}
<NewLineToBr>{Validation}</NewLineToBr>

{Validationinput !=="" ? <br/> : null}
{Validationinput !=="" ? <NewLineToBr>{Validationinput}</NewLineToBr> : null}
{Datecure !=="" & Validationinput===""  ? <br/> : null}
{Datecure !=="" ? "Traitement administré le " : null}
{Datecure[8]}{Datecure[9]} {(Datecure[5]==="0" & Datecure[6]==="1") ? "janvier" : null}{(Datecure[5]==="0" & Datecure[6]==="2") ? "février" : null}{(Datecure[5]==="0" & Datecure[6]==="3") ? "mars" : null}{(Datecure[5]==="0" & Datecure[6]==="4") ? "avril" : null}{(Datecure[5]==="0" & Datecure[6]==="5") ? "mai" : null}{(Datecure[5]==="0" & Datecure[6]==="6") ? "juin" : null}{(Datecure[5]==="0" & Datecure[6]==="7") ? "juillet" : null}{(Datecure[5]==="0" & Datecure[6]==="8") ? "août" : null}{(Datecure[5]==="0" & Datecure[6]==="9") ? "septembre" : null}{(Datecure[5]==="1" & Datecure[6]==="0") ? "octobre" : null}{(Datecure[5]==="1" & Datecure[6]==="1") ? "novembre" : null}{(Datecure[5]==="1" & Datecure[6]==="2") ? "décembre" : null}{(Datecure==="") ? null : " "}{Datecure[0]}{Datecure[1]}{Datecure[2]}{Datecure[3]}
{Datecure !=="" ? "." : null}

{(Datecure!==""&Prochainecuredate!=="") ? <br/> : null}
{(Datecure!==""&Prochainecuredate!=="") ? <br/> : null}

{Prochainecuredate !=="" ? "Réinduction le " : null}
{Prochainecuredate[8]}{Prochainecuredate[9]} {(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="1") ? "janvier" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="2") ? "février" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="3") ? "mars" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="4") ? "avril" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="5") ? "mai" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="6") ? "juin" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="7") ? "juillet" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="8") ? "août" : null}{(Prochainecuredate[5]==="0" & Prochainecuredate[6]==="9") ? "septembre" : null}{(Prochainecuredate[5]==="1" & Prochainecuredate[6]==="0") ? "octobre" : null}{(Prochainecuredate[5]==="1" & Prochainecuredate[6]==="1") ? "novembre" : null}{(Prochainecuredate[5]==="1" & Prochainecuredate[6]==="2") ? "décembre" : null}{(Prochainecuredate==="") ? null : " "}{Prochainecuredate[0]}{Prochainecuredate[1]}{Prochainecuredate[2]}{Prochainecuredate[3]}
{Prochainecuredate !=="" ? "." : null}
{(Prochainecuredate  !=="") & (Examreevaluation !=="" | Examreevaluation  !==""| Prochainecsreferent !=""| Suiteinput !=="")  ? <br/> : null}
{Examreevaluation !=="" ? "Examen(s) de réévaluation : " : null}
{Examreevaluation !=="" ? <NewLineToBr>{Examreevaluation}</NewLineToBr> : null}
{/* {Prochainecsreferent !=="" & Examreevaluation==="" ? <br/> : null} */}
{Prochainecsreferent  !=="" ? "Consultation avec le médecin référent le " : null}
{Prochainecsreferent[8]}{Prochainecsreferent[9]} {(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="1") ? "janvier" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="2") ? "février" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="3") ? "mars" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="4") ? "avril" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="5") ? "mai" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="6") ? "juin" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="7") ? "juillet" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="8") ? "août" : null}{(Prochainecsreferent[5]==="0" & Prochainecsreferent[6]==="9") ? "septembre" : null}{(Prochainecsreferent[5]==="1" & Prochainecsreferent[6]==="0") ? "octobre" : null}{(Prochainecsreferent[5]==="1" & Prochainecsreferent[6]==="1") ? "novembre" : null}{(Prochainecsreferent[5]==="1" & Prochainecsreferent[6]==="2") ? "décembre" : null}{(Prochainecsreferent==="") ? null : " "}{Prochainecsreferent[0]}{Prochainecsreferent[1]}{Prochainecsreferent[2]}{Prochainecsreferent[3]}
{Prochainecsreferent !=="" ? "." : null}
{Suiteinput  !=="" ? <br/> : null}
{Suiteinput  !=="" ? <NewLineToBr>{Suiteinput }</NewLineToBr> : null}



<div className="doses">

<br/>
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !==""|Doseinput!=="") ? <span style={{textDecoration: 'underline' }}>Dose(s) reçue(s) :</span> : null}
<br/>
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" ? "- " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" ? Produit1 : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Doseprevu1 !==""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Doseprevu1 !==""? Doseprevu1 : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Dosetotale1 !=="" & Doseprevu1==="" & Reduction1===""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Dosetotale1 !=="" & (Doseprevu1!=="" | Reduction1!=="")? ", soit " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Dosetotale1 !==""? Dosetotale1 + " (dose totale)": null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Dosecomm1 !=="" & (Doseprevu1!=="" | Dosetotale1!=="") ? ", (" + Dosecomm1 + ")" : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit1 !=="" & Dosecomm1 !=="" & Doseprevu1==="" & Dosetotale1==="" ? " : " + Dosecomm1 : null}



{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2!=="" ? <br/> : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" ? "- " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" ? Produit2 : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Doseprevu2 !==""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Doseprevu2 !==""? Doseprevu2 : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Dosetotale2 !=="" & Doseprevu2==="" & Reduction2===""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Dosetotale2 !=="" & (Doseprevu2!=="" | Reduction2!=="")? ", soit " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Dosetotale2 !==""? Dosetotale2 + " (dose totale)": null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Dosecomm2 !=="" & (Doseprevu2!=="" | Dosetotale2!=="") ? ", (" + Dosecomm2 + ")" : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit2 !=="" & Dosecomm2 !=="" & Doseprevu2==="" & Dosetotale2==="" ? " : " + Dosecomm2 : null}



{/* DOSE 3 */}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3!=="" ? <br/> : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" ? "- " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" ? Produit3 : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Doseprevu3 !==""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Doseprevu3 !==""? Doseprevu3 : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Dosetotale3 !=="" & Doseprevu3==="" & Reduction3===""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Dosetotale3 !=="" & (Doseprevu3!=="" | Reduction3!=="")? ", soit " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Dosetotale3 !==""? Dosetotale3 + " (dose totale)": null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Dosecomm3 !=="" & (Doseprevu3!=="" | Dosetotale3!=="") ? ", (" + Dosecomm3 + ")" : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit3 !=="" & Dosecomm3 !=="" & Doseprevu3==="" & Dosetotale3==="" ? " : " + Dosecomm3 : null}

{/* DOSE 4 */}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4!=="" ? <br/> : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" ? "- " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" ? Produit4 : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Doseprevu4 !==""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Doseprevu4 !==""? Doseprevu4 : null}

{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Dosetotale4 !=="" & Doseprevu4==="" & Reduction4===""? " : " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Dosetotale4 !=="" & (Doseprevu4!=="" | Reduction4!=="")? ", soit " : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Dosetotale4 !==""? Dosetotale4 + " (dose totale)": null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Dosecomm4 !=="" & (Doseprevu4!=="" | Dosetotale4!=="") ? ", (" + Dosecomm4 + ")" : null}
{(Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="")&Produit4 !=="" & Dosecomm4 !=="" & Doseprevu4==="" & Dosetotale4==="" ? " : " + Dosecomm4 : null}


{Doseinput!=="" & (Doseprevu1 !== "" | Dosetotale1 !=="" | Dosecomm1 !==""|Doseprevu2 !== "" | Dosetotale2 !=="" | Dosecomm2 !=="" | Doseprevu3 !== "" | Dosetotale3 !=="" | Dosecomm3 !=="" | Doseprevu4 !== "" | Dosetotale4 !=="" | Dosecomm4 !=="") ? <br/> : null}
{Doseinput !=="" ? <NewLineToBr>{Doseinput}</NewLineToBr> : null}

{(Validation !=="" | Validationinput!=="" | Datecure!=="" | Dosetotale1!=="") ? <br/> : null}
{/* {(Validation !=="" | Validationinput!=="" | Datecure!=="" | Dosetotale1!=="") ? <br/> : null} */}

{/* {(Prochainecuredate !=="" | Examreevaluation  !==""| Prochainecsreferent !=""| Suiteinput !=="") ? <span className="souligne">Suite de la prise en charge</span>: null}
{(Prochainecuredate !=="" | Examreevaluation  !==""| Prochainecsreferent !=""| Suiteinput !=="") ? " : ": null}
{(Prochainecuredate !=="" | Examreevaluation  !==""| Prochainecsreferent !=""| Suiteinput !=="") ? <br/>: null} */}
</div>
</div>
</div>  



</div>


        </div>
  
    
    </div>
</div>
    )
}

export default App;
