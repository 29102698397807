import React from 'react'
import react, { useState, useRef} from 'react';
import './App.css';

const Zonetexte = ({
    setParentvariableinput, placeholderinputtext,
    titre, champ
}) => {

    let [text, setText] = useState('');

    function handleChangeInput(e) {
        setText(e.target.value);
        setParentvariableinput(e.target.value)
      }

    return (



        <div>
            <p className="titrebouton">{titre}</p>
            <textarea className={champ} value={text} onChange={handleChangeInput}  placeholder={placeholderinputtext}></textarea>
        </div>
    )
}

export default Zonetexte
